import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Book1Img from "../../assets/dashboard/book3.jpg";
import ProgressBar from "react-bootstrap/ProgressBar";
import DashboardNavbar from "../DashboardNavbar/DashbaordNavbar";
import donut_dark from "../../assets/dashboard/donut.png";
import donut_dark_bottom from "../../assets/dashboard/Donut-light-big.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaBookReader } from "react-icons/fa";
import DashboardMenu from "../MenuBar/dashboardMenu";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { updateOrInsertBookLanguageStats } from "../../Helpers/statsApiService";
import { toast } from "react-toastify";
import PasswordConfirmation from "../PasswordConfirmation/PasswordConfirmation";
import Profile from "../Profile/Profile";

const config = require("../../Helpers/config.json");

const NewDashboard = () => {
  const navigate = useNavigate();
  const [selectedBook, setSelectedBook] = useState(null);
  const [data, setdata] = useState([]);
  const [Bookdata, setBookdata] = useState([]);
  const [showLangOption, setShowLangOption] = useState(false);
  const [possiblelanguages, setPossiblelanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [discription, setDiscription] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndex2, setHoveredIndex2] = useState(null);
  const [hoveredIndex3, setHoveredIndex3] = useState(null);
  const [hoveredIndex0, setHoveredIndex0] = useState(null);
  const [remainingBooks, setRemainingBooks] = useState([]);
  const [readingBook, setReadingBook] = useState([]);
  const [readingProgress, setReadingProgress] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [girlPower, setGirlPower] = useState([]);
  const [boyPower, setBoyPower] = useState([]);
  const [animal, setAnimal] = useState([]);
  const [celebration, setCelebration] = useState([]);
  const [hoverLeave, setHoverLeave] = useState(false);

  console.log("hover leave", hoverLeave);

  const setDesktopViewport = () => {
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    viewportMetaTag.content = "width=1024"; // Set the width to your desired desktop width
  };

  useEffect(() => {
    setDesktopViewport();

    // Cleanup function to reset the viewport on component unmount
    return () => {
      const viewportMetaTag = document.querySelector('meta[name="viewport"]');
      viewportMetaTag.content = "width=device-width, initial-scale=1.0";
    };
  }, []);

  const readingProgressRef = useRef(null);
  const boyPowerRef = useRef(null);
  const girlPowerRef = useRef(null);
  const animalRef = useRef(null);
  const celebrationRef = useRef(null);
  const [
    showRightArrowForReadingProgress,
    setShowRightArrowForReadingProgress,
  ] = useState(false);
  const [showRightArrowForGirlPowerData, setShowRightArrowForGirlPowerData] =
    useState(false);
  const [showRightArrowForBoyPower, setShowRightArrowForBoyPower] =
    useState(false);
  const [showRightArrowForAnimal, setShowRightArrowForAnimal] = useState(false);
  const [showRightArrowForCelebration, setShowRightArrowForCelebration] =
    useState(false);

  useEffect(() => {
    const containerWidth = boyPowerRef?.current?.scrollWidth;
    const cardWidth = 336;
    const totalWidth = boyPower.length * cardWidth;
    setShowRightArrowForBoyPower(totalWidth > containerWidth);
  }, [boyPower]);

  useEffect(() => {
    const containerWidth = girlPowerRef?.current?.scrollWidth;
    const cardWidth = 336;
    const totalWidth = girlPower?.length * cardWidth;
    setShowRightArrowForGirlPowerData(totalWidth > containerWidth);
  }, [girlPower]);

  useEffect(() => {
    const containerWidth = animalRef?.current?.scrollWidth;
    const cardWidth = 336;
    const totalWidth = animal?.length * cardWidth;
    setShowRightArrowForAnimal(totalWidth > containerWidth);
  }, [animal]);

  useEffect(() => {
    const containerWidth = celebrationRef?.current?.scrollWidth;
    const cardWidth = 336;
    const totalWidth = celebration?.length * cardWidth;
    setShowRightArrowForCelebration(totalWidth > containerWidth);
  }, [celebration]);

  useEffect(() => {
    const containerWidth = readingProgressRef?.current?.scrollWidth;
    const cardWidth = 336;
    const totalWidth = readingProgress.length * cardWidth;
    setShowRightArrowForReadingProgress(totalWidth > containerWidth);
  }, [readingProgress]);

  // Get Category
  useEffect(() => {
    if ("speechSynthesis" in window) window.speechSynthesis.cancel();
    fetch(`${config["baseUrl"]}/getcategories`, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.message === "fetched") {
          let temp = [];
          temp = res.data;
          setdata([...temp]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  const newData = localStorage.getItem("data");
  const profileID = localStorage.getItem("profileId");
  const formatData = JSON.parse(newData);

  useEffect(() => {
    axios
      .get(
        `${config['baseUrl2']}stats/books/getBookReadingProgress/?userId=${formatData?.uid}&profileId=${profileID}`
      )
      .then((res) => {
        if (res && res.data && res.data.data) {
          setReadingBook(res.data.data);
        } else {
          console.error("Invalid response format:", res);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setLoading(true);

    fetch(`${config["baseUrl"]}/getbooks`, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.message === "fetched") {
          const matchingBooks = res.data.filter((book) => {
            const bookIds = readingBook?.map((item) => item?.book_id) || [];
            return bookIds.includes(book?.id);
          });
          const updatedMatchingBooks = matchingBooks.map((book) => {
            const matchingBook = readingBook.find(
              (item) => item?.book_id === book?.id
            );
            return {
              ...book,
              progress: matchingBook?.progress_percentage || 0,
            };
          });
          const nonMatchingBooks = res.data.filter((book) => {
            const bookIds = readingBook?.map((item) => item?.book_id) || [];
            return !bookIds.includes(book?.id);
          });
          const girlPower = res.data?.filter(
            (object) => object?.category_id === "35"
          );
          const boyPower = res.data?.filter(
            (object) => object?.category_id === "36"
          );
          const animal = res.data?.filter(
            (object) => object?.category_id === "31"
          );
          const Celebration = res.data?.filter(
            (object) => object?.category_id === "30"
          );
          localStorage.setItem(
            "continueReadData",
            JSON.stringify(updatedMatchingBooks)
          );
          setReadingProgress([...updatedMatchingBooks]);
          setBookdata([...nonMatchingBooks.slice(0, 9)]);
          setRemainingBooks([...nonMatchingBooks.slice(8)]);
          setGirlPower(girlPower);
          setBoyPower(boyPower);
          setAnimal(animal);
          setCelebration(Celebration);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [readingBook]);

  const getStoryBookLanguages = async (id) => {
    axios
      .get(`${config["baseUrl"]}/getBookLanguages/${id}`)
      .then(({ data }) => {
        if (!!data.data.length) {
          setPossiblelanguages(data?.data);
          setShowLangOption(true);
        } else {
          toast.success("Story is coming soon.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const navigateToBook = (lang) => {
    navigate(`/book-detail?lang=${lang}`, {
      state: { SelectedBook: selectedBook },
    });
    updateOrInsertBookLanguageStats(
      localStorage.getItem("id"),
      localStorage.profileId,
      selectedBook.id,
      1,
      lang
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass("fade-out");

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % Bookdata.length);

        setFadeClass("fade-in");
      }, 100);
    }, 10000);

    return () => clearInterval(interval);
  }, [Bookdata]);

  useEffect(() => {
    const id = Bookdata[currentIndex];
    if (id?.id){
      axios
      .get(`${config["baseUrl"]}/getbookscontent/${id?.id}`)
      .then(({ data: res }) => {
        if (res.message === "fetched") {
          const story = res?.data[0];
          const parser = new DOMParser();
          const doc = parser.parseFromString(story?.content, "text/html");
          const paragraphs = Array.from(doc.querySelectorAll("p")).map(
            (p) => p.textContent
          );
          const newPara = paragraphs.join("\n\n");
          const atIndex = newPara?.indexOf("@");
          const dataBeforeAt =
            atIndex !== -1
              ? newPara.substring(0, atIndex)
              : "Rise and shine, little explorer! As we greet this bright new day, I wonder, what exciting book shall we choose for today's incredible journey? Will it be a story of dazzling dragons, laughing animals, or maybe a space adventure? Let's pick and zoom off into a world of wonder and giggles!";
          setDiscription(dataBeforeAt);
        }
      });
    }
   
  }, [Bookdata, currentIndex]);

  const elementRefo = useRef(); // continue Reading
  const elementRef = useRef(); // girl power
  const elementRef2 = useRef(); // boy power
  const elementRef3 = useRef(); // animal
  const elementRef4 = useRef(); // celebration

  const [arrowDisableLeft, setArrowDisableLeft] = useState({
    initial: true,
    first: true,
    second: true,
    third: true,
    four: true,
  });
  const [arrowDisableRight, setArrowDisableRight] = useState({
    initial: false,
    first: false,
    second: false,
    third: false,
    four: false,
  });

  const handleScroll = (category, element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);

      const isScrollAtEnd =
        element.scrollLeft + element.clientWidth >= element.scrollWidth;

      if (scrollAmount >= distance || isScrollAtEnd) {
        clearInterval(slideTimer);
      }

      setArrowDisableF(element.scrollLeft === 0, isScrollAtEnd, category);
    }, speed);
  };

  const setArrowDisableF = (isLeftDisabled, isRightDisabled, category) => {
    if (category === "initial") {
      setArrowDisableLeft((prevState) => ({
        ...prevState,
        initial: isLeftDisabled,
      }));
      setArrowDisableRight((prevState) => ({
        ...prevState,
        initial: isRightDisabled,
      }));
    } else if (category === "first") {
      setArrowDisableLeft((prevState) => ({
        ...prevState,
        first: isLeftDisabled,
      }));
      setArrowDisableRight((prevState) => ({
        ...prevState,
        first: isRightDisabled,
      }));
    } else if (category === "second") {
      setArrowDisableLeft((prevState) => ({
        ...prevState,
        second: isLeftDisabled,
      }));
      setArrowDisableRight((prevState) => ({
        ...prevState,
        second: isRightDisabled,
      }));
    } else if (category === "third") {
      setArrowDisableLeft((prevState) => ({
        ...prevState,
        third: isLeftDisabled,
      }));
      setArrowDisableRight((prevState) => ({
        ...prevState,
        third: isRightDisabled,
      }));
    } else if (category === "four") {
      setArrowDisableLeft((prevState) => ({
        ...prevState,
        four: isLeftDisabled,
      }));
      setArrowDisableRight((prevState) => ({
        ...prevState,
        four: isRightDisabled,
      }));
    }
  };

  const categories = [
    { id: 19, catName: "Fairy Tales", bg: "red" },
    { id: 29, catName: "Adventure", bg: "blue" },
    { id: 30, catName: "Celebrations", bg: "green" },
    { id: 31, catName: "Animals", bg: "red" },
    { id: 32, catName: "Science Discovery", bg: "blue" },
    { id: 33, catName: "Kindness", bg: "green" },
    { id: 34, catName: "Environment", bg: "red" },
    { id: 35, catName: "Girl Power", bg: "#f35a75" },
    { id: 36, catName: "Boy Power", bg: "#00cbff" },
  ];

  const getCategoryName = (categoryId, categories) => {
    const id = Number(categoryId);
    const matchedCategory = categories.find((category) => category.id === id);
    return matchedCategory ? matchedCategory.catName : "Unknown Category";
  };

  const getCategoryBg = (categoryId, categories) => {
    const id = Number(categoryId);
    const matchedCategory = categories.find((category) => category.id === id);
    return matchedCategory ? matchedCategory.bg : "red";
  };

  return (
    <div className="new-dashboard-wrapper">
      {loading && (
        <div className="loader-overlay">
          <div className="loader" />
        </div>
      )}
      <div className="dashbaord-donut-container">
        <img src={donut_dark} className="dashbaord-donut" />
      </div>
      <DashboardNavbar setShowModal={setShowModal} />
      <img src={donut_dark_bottom} className="dashbaord-donut-bottom" />
      <div className="content-wrrapers">
        <DashboardMenu setShowModal={setShowModal} />
        <div className="sub-content-wrrapers">
          <div className="cards-text-container">
            <div className="text-img-container">
              <div className="text-button-container">
                <div className="happy-hd-container">
                  <h1 className="happy-reading-hd">Happy Reading,</h1>
                  <h1 className="jamal-hd">{localStorage.getItem("name")}</h1>
                </div>
                <div className="happy-text-container">
                  <p className="happy-text">{discription}</p>
                </div>
                <div
                  className="read-now-btn-container"
                  onClick={() => {
                    getStoryBookLanguages(Bookdata[currentIndex]?.id);
                    setSelectedBook(Bookdata[currentIndex]);
                  }}
                >
                  
                  <button className="read-now-btn">Read Now</button>
                </div>
              </div>
              <div className={`img-container ${fadeClass}`}>
                <div className="dashboard-img-book-cover dashboard-img-book-cover-responsive">
                 
                  <img
                    className="dashboard-img main-dashboard-img-responsive"
                    src={ Bookdata[currentIndex]?.image ? 
                      `${config["baseUrl"]}${Bookdata[currentIndex]?.image}` :
                      Book1Img
                    }
                    alt=""
                  />
                  <div className="book-spine"></div>
                </div>

                <p className="dashboard-img-hd">
                  {Bookdata[currentIndex]?.title || ""}
                </p>
              </div>
              <div className="happy-text-container-responsive">
                <p className="happy-text-responsive">
                  Reading books is a enriching activity where individuals engage
                  with written content to gain knowledge, entertainment, and
                  insights. It fosters literacy, critical thinking, and
                  imagination, providing a means to explore diverse ideas,
                  perspectives, and experiences. Whether for education or
                  leisure, reading contributes to personal growth and enjoyment.
                </p>
              </div>
              <div className="read-now-btn-container-responsive">
                <button
                  className="read-now-btn-responsive"
                  onClick={() => {
                    getStoryBookLanguages(Bookdata[currentIndex]?.id);
                    setSelectedBook(Bookdata[currentIndex]);
                  }}
                >
                  Read Now
                </button>
              </div>
            </div>
            <div className="card-main-wraps">
              {readingProgress?.length ? (
                <div className="continue-reading-container">
                  <div className="reading-hd-container">
                    <h3 className="continue-reading-hd">Continue Reading</h3>
                  </div>
                  <div className="scroll-container" ref={readingProgressRef}>
                    {!arrowDisableLeft?.initial && (
                      <div
                        className="arrow left-arrow"
                        onClick={() =>
                          handleScroll(
                            "initial",
                            elementRefo.current,
                            10,
                            250,
                            -10
                          )
                        }
                      >
                        <FaChevronLeft />
                      </div>
                    )}

                    <div className={`all-cards`} ref={elementRefo}>
                      {readingProgress?.map((item, index) => {
                        return (
                          <>
                            <div
                              className="card-container"
                              key={index}
                              onMouseEnter={() => {
                                setHoveredIndex0(index);
                                setHoverLeave(false);
                              }}
                              onMouseLeave={() => {
                                setHoveredIndex0(null);
                              }}
                            >
                              <div className="img-logo-container">
                                <p
                                  className="img-logo"
                                  style={{
                                    background: getCategoryBg(
                                      item?.category_id,
                                      categories
                                    ),
                                  }}
                                >
                                  {getCategoryName(
                                    item?.category_id,
                                    categories
                                  )}
                                </p>
                              </div>
                              <div className="book-cover">
                                <img
                                  src={`${config["baseUrl"]}${item?.image}`}
                                  alt=""
                                  className="card-img"
                                />
                                <div className="book-spine"></div>
                              </div>
                              <ProgressBar
                                variant="warning"
                                now={item?.progress}
                                style={{ height: "8px", marginTop: "15px" }}
                              />
                              <p className="dashboard-img-hd">
                                {/* {item?.title} */}
                              </p>

                              {hoveredIndex0 === index && (
                                <div
                                  className={`highlighted-card-container ${
                                    hoverLeave === true ? "hover-leave" : ""
                                  }`}
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top:
                                      hoveredIndex0 === 0
                                        ? "-10px"
                                        : hoveredIndex0 === Bookdata.length - 1
                                        ? "-10px"
                                        : "-10px",
                                    left:
                                      hoveredIndex0 === 0
                                        ? "0px"
                                        : hoveredIndex0 === Bookdata.length - 1
                                        ? "-85px"
                                        : "-30px",
                                    zIndex: "+5",
                                    transition: "all 0.1s ease-in-out",
                                  }}
                                  onClick={() => {
                                    getStoryBookLanguages(item?.id);
                                    setSelectedBook(item);
                                  }}
                                >
                                  <div className="img-logo-container">
                                    <p
                                      className="img-logo"
                                      style={{
                                        background: getCategoryBg(
                                          item?.category_id,
                                          categories
                                        ),
                                      }}
                                    >
                                      {getCategoryName(
                                        item?.category_id,
                                        categories
                                      )}
                                    </p>
                                  </div>
                                  <div className="highlighted-book-cover">
                                    <img
                                      src={`${config["baseUrl"]}${item?.image}`}
                                      alt=""
                                      className="card-img"
                                    />
                                    <div className="book-spine"></div>
                                  </div>
                                  <div className="highlighted-book-content">
                                    <div className="highlighted-icons">
                                      <div className="highlighted-icons-flex-1">
                                        <div
                                          className="card-btn-icon-wrap"
                                          onClick={() => {
                                            getStoryBookLanguages(item?.id);
                                            setSelectedBook(item);
                                          }}
                                        >
                                          <div className="highlighted-icon">
                                            <FaBookReader
                                              style={{
                                                fontSize: "12px",
                                                // color: "rgb(254, 191, 10)",
                                                color: "white",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p className="card-read-now-btn">
                                              Read Now
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="highlighted-icons-flex-2"></div>
                                    </div>
                                    <p className="highlighted-dashboard-img-hd">
                                      {item?.title}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {!arrowDisableRight?.initial &&
                      showRightArrowForReadingProgress && (
                        <div
                          className="arrow right-arrow"
                          onClick={() =>
                            handleScroll(
                              "initial",
                              elementRefo.current,
                              10,
                              250,
                              10
                            )
                          }
                        >
                          <FaChevronRight />
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {girlPower?.length ? 
                <div className="continue-reading-container">
                  <div className="reading-hd-container">
                    <h3 className="continue-reading-hd">
                      {girlPower?.length ? "Girl Power" : ""}
                    </h3>
                  </div>
                  <div className="scroll-container" ref={girlPowerRef}>
                    {!arrowDisableLeft?.first && (
                      <div
                        className="arrow left-arrow"
                        onClick={() =>
                          handleScroll(
                            "first",
                            elementRef.current,
                            10,
                            250,
                            -10
                          )
                        }
                      >
                        <FaChevronLeft />
                      </div>
                    )}

                    <div className={`all-cards`} ref={elementRef}>
                      {girlPower?.map((item, index) => {
                        return (
                          <>
                            <div
                              className="card-container"
                              key={index}
                              onMouseEnter={() => {
                                setHoveredIndex(index);
                              }}
                              onMouseLeave={() => {
                                setHoveredIndex(null);
                              }}
                            >
                              <div className="img-logo-container">
                                <p
                                  className="img-logo"
                                  style={{
                                    background: getCategoryBg(
                                      item?.category_id,
                                      categories
                                    ),
                                  }}
                                >
                                  {getCategoryName(
                                    item?.category_id,
                                    categories
                                  )}
                                </p>
                              </div>
                              <div className="book-cover">
                                <img
                                  src={`${config["baseUrl"]}${item?.image}`}
                                  alt=""
                                  className="card-img"
                                />
                                <div className="book-spine"></div>
                              </div>
                              <p className="dashboard-img-hd">
                                {/* {item?.title} */}
                              </p>

                              {hoveredIndex === index && (
                                <div
                                  className={`highlighted-card-container `}
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top:
                                      hoveredIndex === 0
                                        ? "-10px"
                                        : hoveredIndex === girlPower.length - 1
                                        ? "-10px"
                                        : "-10px",
                                    left:
                                      hoveredIndex === 0
                                        ? "0px"
                                        : hoveredIndex === girlPower.length - 1
                                        ? "-85px"
                                        : "-30px",
                                    zIndex: "+2",
                                    transition: "all 0.2s ease-in-out",
                                  }}
                                  onClick={() => {
                                    getStoryBookLanguages(item?.id);
                                    setSelectedBook(item);
                                  }}
                                >
                                  <div className="img-logo-container">
                                    <p
                                      className="img-logo"
                                      style={{
                                        background: getCategoryBg(
                                          item?.category_id,
                                          categories
                                        ),
                                      }}
                                    >
                                      {getCategoryName(
                                        item?.category_id,
                                        categories
                                      )}
                                    </p>
                                  </div>
                                  <div className="highlighted-book-cover">
                                    <img
                                      src={`${config["baseUrl"]}${item?.image}`}
                                      alt=""
                                      className="card-img"
                                    />
                                    <div className="book-spine"></div>
                                  </div>
                                  <div className="highlighted-book-content">
                                    <div className="highlighted-icons">
                                      <div className="highlighted-icons-flex-1">
                                        <div
                                          className="card-btn-icon-wrap"
                                          onClick={() => {
                                            getStoryBookLanguages(item?.id);
                                            setSelectedBook(item);
                                          }}
                                        >
                                          <div className="highlighted-icon">
                                            <FaBookReader
                                              style={{
                                                fontSize: "12px",
                                                // color: "rgb(254, 191, 10)",
                                                color: "white",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p className="card-read-now-btn">
                                              Read Now
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="highlighted-icons-flex-2"></div>
                                    </div>
                                    <p className="highlighted-dashboard-img-hd">
                                      {item?.title}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {!arrowDisableRight?.first &&
                      showRightArrowForGirlPowerData && (
                        <div
                          className="arrow right-arrow"
                          onClick={() =>
                            handleScroll(
                              "first",
                              elementRef.current,
                              10,
                              250,
                              10
                            )
                          }
                        >
                          <FaChevronRight />
                        </div>
                      )}
                  </div>
                </div>
                :
                <></>
              }
              {boyPower?.length ? 
                <div className="continue-reading-container">
                  <div className="reading-hd-container">
                    <h3 className="continue-reading-hd">
                      {boyPower?.length ? "Boys Power" : ""}
                    </h3>
                  </div>
                  <div className="scroll-container" ref={boyPowerRef}>
                    {!arrowDisableLeft?.second && (
                      <div
                        className="arrow left-arrow"
                        onClick={() =>
                          handleScroll(
                            "second",
                            elementRef2.current,
                            10,
                            250,
                            -10
                          )
                        }
                      >
                        <FaChevronLeft />
                      </div>
                    )}

                    <div className={`all-cards`} ref={elementRef2}>
                      {boyPower?.map((item, secondindex) => {
                        return (
                          <>
                            <div
                              className="card-container"
                              key={secondindex}
                              onMouseEnter={() => {
                                setHoveredIndex2(secondindex);
                              }}
                              onMouseLeave={() => {
                                setHoveredIndex2(null);
                              }}
                            >
                              <div className="img-logo-container">
                                <p
                                  className="img-logo"
                                  style={{
                                    background: getCategoryBg(
                                      item?.category_id,
                                      categories
                                    ),
                                  }}
                                >
                                  {getCategoryName(
                                    item?.category_id,
                                    categories
                                  )}
                                </p>
                              </div>
                              <div className="book-cover">
                                <img
                                  src={`${config["baseUrl"]}${item?.image}`}
                                  alt=""
                                  className="card-img"
                                />
                                <div className="book-spine"></div>
                              </div>
                              <p className="dashboard-img-hd">
                                {/* {item?.title} */}
                              </p>
                              {hoveredIndex2 === secondindex && (
                                <div
                                  className={`highlighted-card-container `}
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top:
                                      hoveredIndex2 === 0
                                        ? "-20px"
                                        : hoveredIndex2 === boyPower.length - 1
                                        ? "-20px"
                                        : "-20px",
                                    left:
                                      hoveredIndex2 === 0
                                        ? "0px"
                                        : hoveredIndex2 === boyPower.length - 1
                                        ? "-85px"
                                        : "-20px",
                                    zIndex: "+2",
                                    transition: "all 0.2s ease-in-out",
                                  }}
                                  onClick={() => {
                                    getStoryBookLanguages(item?.id);
                                    setSelectedBook(item);
                                  }}
                                >
                                  <div className="img-logo-container">
                                    <p
                                      className="img-logo"
                                      style={{
                                        background: getCategoryBg(
                                          item?.category_id,
                                          categories
                                        ),
                                      }}
                                    >
                                      {getCategoryName(
                                        item?.category_id,
                                        categories
                                      )}
                                    </p>
                                  </div>
                                  <div className="highlighted-book-cover">
                                    <img
                                      src={`${config["baseUrl"]}${item?.image}`}
                                      alt=""
                                      className="card-img"
                                    />
                                    <div className="book-spine"></div>
                                  </div>
                                  <div className="highlighted-book-content">
                                    <div className="highlighted-icons">
                                      <div className="highlighted-icons-flex-1">
                                        <div
                                          className="card-btn-icon-wrap"
                                          onClick={() => {
                                            getStoryBookLanguages(item?.id);
                                            setSelectedBook(item);
                                          }}
                                        >
                                          <div className="highlighted-icon">
                                            <FaBookReader
                                              style={{
                                                fontSize: "12px",
                                                // color: "rgb(254, 191, 10)",
                                                color: "white",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p className="card-read-now-btn">
                                              Read Now
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="highlighted-icons-flex-2"></div>
                                    </div>
                                    <p className="highlighted-dashboard-img-hd">
                                      {item?.title}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {!arrowDisableRight?.second &&
                      showRightArrowForBoyPower && (
                        <div
                          className="arrow right-arrow"
                          onClick={() =>
                            handleScroll(
                              "second",
                              elementRef2.current,
                              10,
                              250,
                              10
                            )
                          }
                        >
                          <FaChevronRight />
                        </div>
                      )}
                  </div>
                </div>
              :<></>}
              {animal?.length ?
                <div className="continue-reading-container">
                  <div className="reading-hd-container">
                    <h3 className="continue-reading-hd">
                      {animal?.length ? "Animal" : ""}
                    </h3>
                  </div>
                  <div className="scroll-container" ref={animalRef}>
                    {!arrowDisableLeft?.third && (
                      <div
                        className="arrow left-arrow"
                        onClick={() =>
                          handleScroll(
                            "third",
                            elementRef3.current,
                            10,
                            250,
                            -10
                          )
                        }
                      >
                        <FaChevronLeft />
                      </div>
                    )}

                    <div className={`all-cards`} ref={elementRef3}>
                      {animal?.map((item, secondindex) => {
                        return (
                          <>
                            <div
                              className="card-container"
                              key={secondindex}
                              onMouseEnter={() => {
                                setHoveredIndex3(secondindex);
                              }}
                              onMouseLeave={() => {
                                setHoveredIndex3(null);
                              }}
                            >
                              <div className="img-logo-container">
                                <p
                                  className="img-logo"
                                  style={{
                                    background: getCategoryBg(
                                      item?.category_id,
                                      categories
                                    ),
                                  }}
                                >
                                  {getCategoryName(
                                    item?.category_id,
                                    categories
                                  )}
                                </p>
                              </div>
                              <div className="book-cover">
                                <img
                                  src={`${config["baseUrl"]}${item?.image}`}
                                  alt=""
                                  className="card-img"
                                />
                                <div className="book-spine"></div>
                              </div>
                              <p className="dashboard-img-hd">
                                {/* {item?.title} */}
                              </p>
                              {hoveredIndex3 === secondindex && (
                                <div
                                  className={`highlighted-card-container `}
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top:
                                      hoveredIndex3 === 0
                                        ? "-20px"
                                        : hoveredIndex3 === animal.length - 1
                                        ? "-20px"
                                        : "-20px",
                                    left:
                                      hoveredIndex3 === 0
                                        ? "0px"
                                        : hoveredIndex3 === animal.length - 1
                                        ? "-85px"
                                        : "-20px",
                                    zIndex: "+2",
                                    transition: "all 0.2s ease-in-out",
                                  }}
                                  onClick={() => {
                                    getStoryBookLanguages(item?.id);
                                    setSelectedBook(item);
                                  }}
                                >
                                  <div className="img-logo-container">
                                    <p
                                      className="img-logo"
                                      style={{
                                        background: getCategoryBg(
                                          item?.category_id,
                                          categories
                                        ),
                                      }}
                                    >
                                      {getCategoryName(
                                        item?.category_id,
                                        categories
                                      )}
                                    </p>
                                  </div>
                                  <div className="highlighted-book-cover">
                                    <img
                                      src={`${config["baseUrl"]}${item?.image}`}
                                      alt=""
                                      className="card-img"
                                    />
                                    <div className="book-spine"></div>
                                  </div>
                                  <div className="highlighted-book-content">
                                    <div className="highlighted-icons">
                                      <div className="highlighted-icons-flex-1">
                                        <div
                                          className="card-btn-icon-wrap"
                                          onClick={() => {
                                            getStoryBookLanguages(item?.id);
                                            setSelectedBook(item);
                                          }}
                                        >
                                          <div className="highlighted-icon">
                                            <FaBookReader
                                              style={{
                                                fontSize: "12px",
                                                // color: "rgb(254, 191, 10)",
                                                color: "white",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p className="card-read-now-btn">
                                              Read Now
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="highlighted-icons-flex-2"></div>
                                    </div>
                                    <p className="highlighted-dashboard-img-hd">
                                      {item?.title}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {!arrowDisableRight?.third && showRightArrowForAnimal && (
                      <div
                        className="arrow right-arrow"
                        onClick={() =>
                          handleScroll(
                            "third",
                            elementRef3.current,
                            10,
                            250,
                            10
                          )
                        }
                      >
                        <FaChevronRight />
                      </div>
                    )}
                  </div>
                </div>
              :<></>}
              {celebration?.length ?
                <div className="continue-reading-container">
                  <div className="reading-hd-container">
                    <h3 className="continue-reading-hd">
                      {celebration?.length ? "Celebration" : ""}
                    </h3>
                  </div>
                  <div className="scroll-container" ref={celebrationRef}>
                    {!arrowDisableLeft?.four && (
                      <div
                        className="arrow left-arrow"
                        onClick={() =>
                          handleScroll(
                            "four",
                            elementRef4.current,
                            10,
                            250,
                            -10
                          )
                        }
                      >
                        <FaChevronLeft />
                      </div>
                    )}

                    <div className={`all-cards`} ref={elementRef4}>
                      {celebration?.map((item, secondindex) => {
                        return (
                          <>
                            <div
                              className="card-container"
                              key={secondindex}
                              onMouseEnter={() => {
                                setHoveredIndex2(secondindex);
                              }}
                              onMouseLeave={() => {
                                setHoveredIndex2(null);
                              }}
                            >
                              <div className="img-logo-container">
                                <p
                                  className="img-logo"
                                  style={{
                                    background: getCategoryBg(
                                      item?.category_id,
                                      categories
                                    ),
                                  }}
                                >
                                  {getCategoryName(
                                    item?.category_id,
                                    categories
                                  )}
                                </p>
                              </div>
                              <div className="book-cover">
                                <img
                                  src={`${config["baseUrl"]}${item?.image}`}
                                  alt=""
                                  className="card-img"
                                />
                                <div className="book-spine"></div>
                              </div>
                              <p className="dashboard-img-hd">
                                {/* {item?.title} */}
                              </p>
                              {hoveredIndex2 === secondindex && (
                                <div
                                  className={`highlighted-card-container`}
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top:
                                      hoveredIndex2 === 0
                                        ? "-20px"
                                        : hoveredIndex2 === Bookdata.length - 1
                                        ? "-20px"
                                        : "-20px",
                                    left:
                                      hoveredIndex2 === 0
                                        ? "0px"
                                        : hoveredIndex2 === Bookdata.length - 1
                                        ? "-85px"
                                        : "-20px",
                                    zIndex: "+4",
                                    transition: "all 0.1s ease-in-out",
                                  }}
                                  onClick={() => {
                                    getStoryBookLanguages(item?.id);
                                    setSelectedBook(item);
                                  }}
                                >
                                  <div className="img-logo-container">
                                    <p
                                      className="img-logo"
                                      style={{
                                        background: getCategoryBg(
                                          item?.category_id,
                                          categories
                                        ),
                                      }}
                                    >
                                      {getCategoryName(
                                        item?.category_id,
                                        categories
                                      )}
                                    </p>
                                  </div>
                                  <div className="highlighted-book-cover">
                                    <img
                                      src={`${config["baseUrl"]}${item?.image}`}
                                      alt=""
                                      className="card-img"
                                    />
                                    <div className="book-spine"></div>
                                  </div>
                                  <div className="highlighted-book-content">
                                    <div className="highlighted-icons">
                                      <div className="highlighted-icons-flex-1">
                                        <div
                                          className="card-btn-icon-wrap"
                                          onClick={() => {
                                            getStoryBookLanguages(item?.id);
                                            setSelectedBook(item);
                                          }}
                                        >
                                          <div className="highlighted-icon">
                                            <FaBookReader
                                              style={{
                                                fontSize: "12px",
                                                // color: "rgb(254, 191, 10)",
                                                color: "white",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p className="card-read-now-btn">
                                              Read Now
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="highlighted-icons-flex-2"></div>
                                    </div>
                                    <p className="highlighted-dashboard-img-hd">
                                      {item?.title}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>

                    {!arrowDisableRight?.four &&
                      showRightArrowForCelebration && (
                        <div
                          className="arrow right-arrow"
                          onClick={() =>
                            handleScroll(
                              "four",
                              elementRef4.current,
                              10,
                              250,
                              10
                            )
                          }
                        >
                          <FaChevronRight />
                        </div>
                      )}
  
                    <div className="language-modal">
                      {showLangOption && (
                        <Modal
                          show={showLangOption}
                          onHide={() => setShowLangOption(false)}
                          animation={false}
                          size="md"
                          centered
                        >
                          <Modal.Header
                            closeButton
                            className="lang-modal-header"
                          >
                            <Modal.Title>
                              Please Select a language of book
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="lang-modal-body">
                            <div className="all-btns-wrap">
                              {possiblelanguages.length > 0 &&
                                possiblelanguages?.map((lang) => {
                                  return (
                                    <Button
                                      className="all-btns"
                                      variant="warning"
                                      onClick={() => navigateToBook(lang)}
                                    >
                                      {lang?.charAt(0)?.toUpperCase() +
                                        lang?.slice(1)}
                                    </Button>
                                  );
                                })}
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              :<></>}

              {
                !(readingProgress?.length || girlPower?.length|| boyPower?.length || animal?.length || celebration?.length) ?<p className="no-data-hd">No Books Found!</p>:<></>
              }
            </div>
          </div>
        </div>
      </div>
      {
        <PasswordConfirmation
          showModal={showModal}
          setShowModal={setShowModal}
          route={"/account"}
        />
      }
      <div>
        
      </div>
    </div>
  );
};

export default NewDashboard;
